<template>
  <div>
    <!-- UniverseTheGame/images/pic4EB3BA0C37472163761754B8D690CEA7.png -->
    <banner-module :img="require('@/assets/the-game/what-is-lu.png')"></banner-module>
    <text-module title="ALL HEROES TO THE RESCUE!">
      <div style="margin: 0px 10px 10px">
        <span style="clear: both; display: block; margin-bottom: 20px"
          ><!-- UniverseTheGame/images/picA350A23190EFC84F2B32214B8A88EF40.png --><img
            style="margin-top: -50px; float: right"
            src="@/assets/the-game/ag-island2.png"
          />
          <p style="width: 430px">
            Every world in LEGO Universe has been attacked by a mighty force of
            evil. Our enemies are invading our worlds, destroying our buildings,
            and corrupting our people.
          </p>
          <p style="width: 430px">
            To win this epic battle, players from around the globe must come
            together to build their own LEGO heroes and join us - the Nexus
            Force - to save the universe!
          </p>
        </span>
        <router-link
          style="float: right; margin: 0px 0px 200px 30px"
          to="/the-game/getting-started/free-to-play"
          ><!-- UniverseTheGame/images/pic92F279BDD2B54F72FF14C764676DCE87.png
          --><img src="@/assets/the-game/free-to-play.png" />
        </router-link>

        <!-- UniverseTheGame/images/picA12D50C60F6292B236348C94F3133233.png -->
        <span style="display: block; margin-bottom: 20px; margin-left: 30px"
          ><img
            style="float: left; margin-left: -30px"
            src="@/assets/the-game/star-bullet.png"
          />
          Battle enemies across many worlds inside LEGO Universe.</span
        >

        <span style="display: block; margin-bottom: 20px; margin-left: 30px"
          ><img
            style="float: left; margin-left: -30px"
            src="@/assets/the-game/star-bullet.png"
          />
          Earn extra gear and powers by working your way up the ranks of the
          Nexus Force.</span
        >

        <span style="display: block; margin-bottom: 20px; margin-left: 30px"
          ><img
            style="float: left; margin-left: -30px"
            src="@/assets/the-game/star-bullet.png"
          />
          Use your creative skills to build fortresses and defense systems
          protecting your property against the chaotic Maelstrom.</span
        >

        <span style="display: block; margin-bottom: 20px; margin-left: 30px"
          ><img
            style="float: left; margin-left: -30px"
            src="@/assets/the-game/star-bullet.png"
          />
          Team up with friends in the fight against evil.</span
        >

        <span style="display: block; margin-bottom: 20px; margin-left: 30px"
          ><img
            style="float: left; margin-left: -30px"
            src="@/assets/the-game/star-bullet.png"
          />
          And much, much more…</span
        >

        <span
          style="
            display: block;
            font-weight: bold;
            font-size: 16px;
            margin: 40px 0px 20px;
            color: #fff;
          "
          >LEGO Universe in Brief</span
        >
        <span style="display: block; margin-bottom: 20px"
          ><span
            style="
              display: block;
              font-weight: bold;
              font-size: 14px;
              color: #fff;
            "
            >What?</span
          >
          LEGO Universe is a Massively Multiplayer Online Game (MMOG)</span
        >
        <span style="display: block; margin-bottom: 20px"
          ><span
            style="
              display: block;
              font-weight: bold;
              font-size: 14px;
              color: #fff;
            "
            >How?</span
          >
          You download the game from LEGOuniverse.com and play online through
          our closed and secure game browser.</span
        >
        <span style="display: block; margin-bottom: 20px"
          ><span
            style="
              display: block;
              font-weight: bold;
              font-size: 14px;
              color: #fff;
            "
            >Who?</span
          >
          You play alongside other players from around the world (all players
          are anonymous, no personal information is exchanged).</span
        >
        <span style="display: block; margin-bottom: 20px"
          ><span
            style="
              display: block;
              font-weight: bold;
              font-size: 14px;
              color: #fff;
            "
            >What else?</span
          >
          LEGO Universe expands all the time with new worlds, new missions and
          new battles. What’s more, our team of live moderators work around the
          clock to fulfill our target of being the safest game on the
          Internet.</span
        >
        <span style="display: block; margin-bottom: 20px"
          ><span
            style="
              display: block;
              font-weight: bold;
              font-size: 14px;
              color: #fff;
            "
            >More info?</span
          >
          Visit the Parent section on LEGOuniverse.com</span
        >
      </div>
    </text-module>
  </div>
</template>
